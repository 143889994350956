<template>
  <div class="cart">
    <van-nav-bar
      title="购物车"
      :right-text="editCartText"
      @click-right="onClickRight"
      @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" v-if="showback"></van-icon>
      </template>
    </van-nav-bar>
    <!-- 产品列表 -->
    <div class="goods_main" v-if="goods && goods.length > 0 ? true : false">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <van-cell
          :border="false"
          v-for="(item, index) in goods"
          :key="index"
          @click="checkboxChange(index)"
        >
          <template #icon>
            <van-checkbox :name="index" ref="checkboxes"></van-checkbox>
          </template>
          <template #title>
            <van-card
              :price="item.price | currencyFormat"
              :desc="item.option_title"
              :title="item.title"
              :thumb="item.thumb"
              @click-thumb.stop="details(item.goodsid)"
              lazy-load
              :style="'max-width:' + (screenWidth - 50) + 'px;'"
            >
              <template #num>
                <van-stepper
                  @change="numChange"
                  :name="index"
                  v-model="item.total"
                  integer
                  :min="1"
                  :max="item.stock"
                />
              </template>
              <template #tag>
                {{strId}}
              </template>
            </van-card>
          </template>
        </van-cell>
      </van-checkbox-group>
    </div>
    <div class="goods_main" v-if="nodata">
      <van-empty description="空空如也" />
    </div>
    <!-- 底部按钮 -->
    <div class="cart_bottom" v-if="goods && goods.length > 0 ? true : false">
      <van-submit-bar
        :price="totalPrice"
        button-text="去结算"
        @submit="onSubmit"
        :disabled="submitBtn"
      >
        <van-checkbox v-model="checked" @click="checkAll" ref="allCheck">
          全选
        </van-checkbox>
      </van-submit-bar>
      <div class="cart_edit" v-show="editCart">
        <van-button round @click="favoriter">移入收藏</van-button>
        <van-button round @click="delCart">删除</van-button>
      </div>
    </div>
    <div class="loadinging" v-show="showLoading">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: "Cart",
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      /*
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "/" + MM + "/" + d; // + ' ' + h + ':' + m + ':' + s;
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      goods: [], // 产品列表
      editCart: false, // 默认不编辑购物车产品
      editCartText: "管理",
      checked: false, // 是否全选
      value: 2,
      islogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false, // 是否是登录状态
      totalPrice: 0, // 商品总价
      totalNum: 0, // 商品个数（有种商品）
      result: [], // 复选框合集
      submitBtn: true, // 提交按钮是否禁用
      showback: false, // 默认隐藏返回按钮
      numEdit: false, // 是否需要修改产品数量
      nodata: false, // 是否是空数据
      checkedGid: 0, // 已选中产品id
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      showLoading: false, // 默认不显示
    };
  },
  activated() {
    this.showback = this.$route.query.showback
      ? this.$route.query.showback
      : false;
    this.goods = [];
    this.strId = localStorage.getItem("DK_STRID");
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "购物车");
    this.init();
    this.$parent.getCartNum();
  },
  methods: {
    init() { 
      if (this.merchid > 0) {
        this.setmerchMber();
      }
      
      this.getgoods();
    },
    // 获取产品列表
    getgoods() {
      let _that = null;
      _that = this;

      if (!_that.islogin) {
        _that.goods = [];
        _that.nodata = true;
      } else {
        _that.showLoading = true;
        const UID = localStorage.getItem("DK_UID")
          ? localStorage.getItem("DK_UID")
          : "";
        _that.$axios.defaults.headers.common["Authorization"] = UID;
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/cart/get_cart",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(response => {
            _that.showLoading = false;
            console.log(response);
            if (response.data.code == 100000) {
              _that.nodata = false;
              _that.goods = response.data.data;
              _that.totalNum = _that.goods.length;
              _that.getCartMoney(); // 获取购物车总价格
            }else{
              _that.nodata = true;
            }
          })
          .catch(erro => {
            console.log(erro);
            _that.nodata = true;
            _that.showLoading = false;
          });
      }
    },
    // 编辑购物车产品
    onClickRight() {
      if (this.editCart) {
        this.editCart = false;
        this.editCartText = "管理";
        this.totalPrice = 0;
        this.$refs.allCheck.toggle(false);
        this.$refs.checkboxes.forEach(item => {
          item.toggle(false);
        })
        this.submitBtn = true; // 禁用提交按钮
      } else {
        this.editCart = true;
        this.editCartText = "完成";
      }
    },
    // 改变商品数量
    numChange(val, detal) {
      event.stopPropagation(); //原生阻止冒泡事件
      let _that = null;
      _that = this;
      _that.showLoading = true;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/cart/update_num",
          _that.$qs.stringify({
            id: _that.goods[detal.name].id,
            num: val
          })
        )
        .then(res => {
          console.log(res);
          _that.showLoading = false;
          if (res.data.code == 100000) {
            _that.goods[detal.name].total = val;
            _that.goods[detal.name].price = res.data.data;
            _that.getCartMoney(); // 获取购物车总价格
          }
        })
        .catch(error => {
          console.log(error);
          _that.showLoading = false;
        })
        
      this.numEdit = true;
    },
    // 单独选择
    checkboxChange(index) {
      let _that = null;
      _that = this;
      
      if (this.editCart) { // 购物车编辑
        _that.$refs.checkboxes[index].toggle();
      }else { // 选中产品进行价格计算
        let id = 0;
        id = parseInt(this.goods[index].goodsid);
        
        // 改变复选框状态
        this.$refs.checkboxes[index].toggle();
        if (this.result.indexOf(index) >= 0) {
          this.result.splice(this.result.indexOf(index), 1);
        } else {
          this.result.push(index);
        }

        this.allChecked();
        this.getCartMoney(); // 获取购物车总价格
        /*
        if (this.shareid == 69041 || this.shareid == 57807 || parseInt(_that.shareid) == 42497 || parseInt(_that.shareid) == 4) { // 合单支付
          // 改变复选框状态
          this.$refs.checkboxes[index].toggle();
          if (this.result.indexOf(index) >= 0) {
            this.result.splice(this.result.indexOf(index), 1);
          } else {
            this.result.push(index);
          }

          this.allChecked();
          this.getCartMoney(); // 获取购物车总价格
        }else { // 不合单支付
          if(this.checkedGid > 0 && this.checkedGid != id) {
            this.$dialog.alert({
              title: "提示",
              message: "该产品和已选中产品不同，是否选择此产品？",
              showCancelButton: true, // 是否展示取消按钮
              beforeClose: function(action, done) {
                console.log(action);
                if (action == "cancel") {
                  console.log("取消按钮");

                  _that.$refs.checkboxes[index].toggle(false);
                  
                  setTimeout(() => {
                    _that.allChecked();
                    _that.getCartMoney(); // 获取购物车总价格
                  },100)
                }
                if (action == "confirm") {
                  console.log("确认按钮");
                  // 改变选中产品id
                  _that.checkedGid = id;
                  
                  // 改变复选框状态
                  _that.$refs.checkboxes.forEach((t, i) => {
                    if (t.checked == true) {
                      t.toggle();
                    }
                  })
                  // 切换为选中状态
                  _that.$refs.checkboxes[index].toggle(true);
                  _that.result = [];
                  _that.result.push(index);
                  
                  _that.allChecked();
                  _that.getCartMoney(); // 获取购物车总价格
                }
                done();
              }
            })
          }else {
            this.checkedGid = id;
            // 改变复选框状态
            this.$refs.checkboxes[index].toggle();
            
            if (this.result.indexOf(index) >= 0) {
              this.result.splice(this.result.indexOf(index), 1);
            } else {
              this.result.push(index);
            }

            this.allChecked();
            this.getCartMoney(); // 获取购物车总价格
          }
        
        }

        */   
      } 
    },
    // 全选框是否是全选状态
    allChecked() {
      if (this.editCart) {
        // 产品编辑状态
        if (this.result.length == this.goods.length) {
          this.$refs.allCheck.toggle(true);
        } else {
          this.$refs.allCheck.toggle(false);
        }
      }else {
        // 非产品编辑状态
        let num = 0;
        this.goods.forEach((item, index) => {
          if (item.goodsid == this.checkedGid) {
            num++;
          }
        })
        
        if (this.result.length == num && this.result.length > 0) {
          this.$refs.allCheck.toggle(true);
        }else{
          this.$refs.allCheck.toggle(false);
        }
      }
    },
    // 跳转详情页
    details(id) {
      if (this.numEdit) {
        this.updateCate();
      }
      this.$router.push({ name: "Details", query: { id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 全选
    checkAll() {
      // 购物车中编辑产品时可以全选
      if (this.editCart) {
        if (this.result.length < this.goods.length) {
          this.$refs.checkboxGroup.toggleAll(true);
          this.$refs.allCheck.toggle(true);
          this.submitBtn = false; // 启用提交按钮
        } else {
          this.$refs.checkboxGroup.toggleAll(false);
          this.$refs.allCheck.toggle(false);
          this.submitBtn = true; // 禁用提交按钮
        }
      }else{
        // 非编辑状态
        let checkedNum = 0;

        if (this.result.length < this.goods.length) {
          this.$refs.checkboxGroup.toggleAll(true);
          this.$refs.allCheck.toggle(true);
          this.submitBtn = false; // 启用提交按钮
        } else {
          this.$refs.checkboxGroup.toggleAll(false);
          this.$refs.allCheck.toggle(false);
          this.submitBtn = true; // 禁用提交按钮
        }

        this.$refs.checkboxes.forEach((item, index) => {
          if(this.goods[index].goodsid == this.checkedGid) {
            item.toggle(true);
            checkedNum++ ;
          }
        })

        /*
        if (this.shareid == 69041 || this.shareid == 57807 || parseInt(this.shareid) == 42497 || parseInt(this.shareid) == 4) { // 合单支付
          if (this.result.length < this.goods.length) {
            this.$refs.checkboxGroup.toggleAll(true);
            this.$refs.allCheck.toggle(true);
            this.submitBtn = false; // 启用提交按钮
          } else {
            this.$refs.checkboxGroup.toggleAll(false);
            this.$refs.allCheck.toggle(false);
            this.submitBtn = true; // 禁用提交按钮
          }
        }else { // 不合单支付
          if (this.checkedGid > 0) {
            this.$refs.checkboxes.forEach((item, index) => {
              if(this.goods[index].goodsid == this.checkedGid) {
                item.toggle(true);
                checkedNum++ ;
              }
            })
          }else {
            this.checkedGid = this.goods[0].goodsid;
            this.$refs.checkboxes.forEach((item, index) => {
              if(this.goods[index].goodsid == this.checkedGid) {
                item.toggle(true);
                checkedNum++ ;
              }
            })
          }
        }
        */
        if (this.result.length == checkedNum) {
          this.$refs.checkboxes.forEach((item, index) => {
            if(this.goods[index].goodsid == this.checkedGid) {
              item.toggle();
            }
          })
        }
        setTimeout(() => {
          this.getCartMoney(); // 获取购物车总价格
        },200)
        
      }
    },
    // 删除产品
    delCart() {
      let _that = null;
      _that = this;
      const UID = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : "";
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      if (_that.result.length <= 0) {
        _that.$toast("您还没有选择商品");
      } else {
        const cartid = [];
        _that.result.forEach(item => {
          cartid.push(_that.goods[item].id);
        });
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/cart/del_cart",
            _that.$qs.stringify({
              ids: cartid,
              merchid: _that.merchid
            })
          )
          .then(response => {
            if (response && response.data && response.data.code == 100000) {
              _that.totalPrice = 0;
              _that.$notify({
                type: "success",
                message: response.data.msg ? response.data.msg : "删除成功",
                duration: 1500
              });
              if (_that.result.length >= _that.goods.length) {
                // 全部删除
                _that.goods = [];
                // 全部删除后 显示空状态
                _that.nodata = true;
                // 已经删除完成了，就不要再显示完成按钮了
                _that.onClickRight();
              } else {
                console.log(_that.result);
                _that.result.forEach(item => {
                  _that.goods.splice(item, 1);
                });
                if (_that.goods.length <= 0) {
                  // 全部删除后 显示空状态
                  _that.nodata = true;
                  // 已经删除完成了，就不要再显示完成按钮了
                  _that.onClickRight();
                }
              }
              _that.result = [];
            } else {
              _that.$notify({
                type: "warning",
                message: response.data.msg ? response.data.msg : "删除失败",
                duration: 1500
              });
            }
            _that.$parent.getCartNum();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    // 移入收藏
    favoriter() {
      let _that = null;
      _that = this;
      const UID = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : "";
      if (_that.result.length <= 0) {
        _that.$toast("您还没有选择商品");
      } else {
        const cartid = [];
        _that.result.forEach(item => {
          cartid.push(_that.goods[item].id);
        });
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/cart/set_favoriter",
            _that.$qs.stringify({
              ids: cartid,
              uid: UID,
              merchid: _that.merchid
            })
          )
          .then(response => {
            if (response && response.data && response.data.code == 100000) {
              _that.$notify({
                type: "success",
                message: response.data.msg ? response.data.msg : "移入收藏成功",
                duration: 1500
              });
              _that.result.forEach(item => {
                _that.goods.splice(item, 1);
              });
              _that.result = [];
            } else {
              _that.$notify({
                type: "warning",
                message: response.data.msg ? response.data.msg : "移入收藏失败",
                duration: 1500
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    // 去结算按钮
    onSubmit() {
      let _that = null;
      _that = this;
      const goods = [];
      _that.result.forEach(item => {
        const arr = {
          goodsid: _that.goods[item].goodsid,
          optionid: _that.goods[item].optionid,
          num: _that.goods[item].total,
          merchid: _that.merchid
        };
        goods.push(arr);
      });
      if (_that.numEdit) {
        _that.updateCate();
      }
      localStorage.setItem("DK_DATA", _that.$qs.stringify(goods));
      
      _that.$router.push({ name: "V1Payment", query: { shareid: _that.shareid, merchid: _that.merchid } }); // 合单支付
      // console.log(parseInt(_that.shareid));
      // if (parseInt(_that.shareid) == 69041 || parseInt(_that.shareid) == 57807 || parseInt(_that.shareid) == 42497 || parseInt(_that.shareid) == 4) { // 合单支付
      //   console.log(111);
      //   _that.$router.push({ name: "V1Payment", query: { shareid: _that.shareid, merchid: _that.merchid } });
      // }else { // 不合单支付
      //   console.log(222);
      //   _that.$router.push({ name: "Payment", query: { shareid: _that.shareid, merchid: _that.merchid } });
      // }
    },
    // 更新购物车中产品数量
    updateCate() {
      let _that = null;
      _that = this;
      const goods = [];
      _that.goods.forEach(item => {
        const arr = {
          id: item.id,
          num: item.total
        };
        goods.push(arr);
      });
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      // 更新购物车表的数量
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/cart/cart_edit",
          _that.$qs.stringify({
            goods: goods,
            merchid: _that.merchid
          })
        )
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 返回按钮
    onClickLeft() {
      if (this.numEdit) {
        this.updateCate();
      }
      if (this.showback) {
        this.$router.go(-1);
      }
    },
    // 计算购物车已选总价格
    getCartMoney() {
      let _that = null;
      _that = this;
      // 总价
      _that.totalPrice = 0;
      // 重新计算总价
      _that.result.forEach(item => {
        _that.totalPrice += _that.goods[item].price * _that.goods[item].total;
      });
      if (_that.totalPrice > 0) {
        this.submitBtn = false; // 启用提交按钮
      }else {
        this.submitBtn = true; // 禁用提交按钮
        this.checkedGid = 0;
      }
    },
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    }
  }
};
</script>
<style lang="less">
.cart {
  width: 100%;
  min-height: 100%;
  background: #ededed;
  overflow: hidden;
  // 标题
  .van-nav-bar {
    .van-nav-bar__left {
      .van-icon {
        font-size: 20px;
        color: #333;
      }
    }
    .van-nav-bar__right {
      .van-nav-bar__text {
        color: #333;
      }
    }
  }
  // 产品列表

  .goods_main {
    padding-bottom: 110px;
    .van-cell {
      margin-top: 10px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px;
      .van-cell__title {
        flex: 1;
        padding-left: 10px;
        text-align: left;
        .van-card {
          background-color: #fff;
          padding: 0;
          .van-card__price {
            color: #d22625;
          }
          .van-card__tag{
            top: inherit;
            left: inherit;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            background-color: #fff;
            color: #808080;
            padding: 0 2px;
            font-size: 14px;
            line-height:16px
          }
        }
      }
      .van-checkbox__icon--checked .van-icon {
        background-color: #d22625;
        border-color: #d22625;
      }
    }
  }

  // 底部按钮
  .cart_bottom {
    position: fixed;
    left: 0;
    bottom: 50px;
    right: 0;
    background: #fff;
    min-height: 50px;
    .van-submit-bar {
      position: absolute;
      .van-submit-bar__bar {
        padding: 0 10px;
      }
    }
    .van-checkbox__icon--checked .van-icon {
      background-color: #d22625;
      border-color: #d22625;
    }
    .cart_edit {
      position: fixed;
      bottom: 50px;
      right: 0;
      left: 70px;
      padding: 0 10px;
      z-index: 101;
      background-color: #fff;
      height: 50px;
      line-height: 50px;
      text-align: right;
      .van-button {
        height: 36px;
        line-height: 34px;
        margin-left: 10px;
      }
    }
  }
  .loadinging {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0);
    .van-loading{
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 20px;
      border-radius: 10px;
      color: #fff;
      .van-loading__text {
        color: #fff;
      }
    }
  }
}
</style>
